<template>
  <nav class="m-auto w-full p-4 flex items-center absolute">
    <div class="container mx-auto flex justify-between items-center">
      <div class="text-white text-lg font-bold">
        <img
          src="@/assets/logo-white.svg"
          alt="Restaurant Logo"
          class="h-24 md:h-40"
        />
      </div>
      <div class="hidden md:flex space-x-6 text-xl">
        <a
          href="#"
          @click.prevent="scrollTo('#hero')"
          class="text-white hover:text-gray-300"
          >Over ons</a
        >
        <a
          href="#"
          @click.prevent="scrollTo('#gallery')"
          class="text-white hover:text-gray-300"
          >Foto's</a
        >
        <a
          href="#"
          @click.prevent="scrollTo('#booking')"
          class="text-white hover:text-gray-300"
          >Reserveren</a
        >
        <a
          href="#"
          @click.prevent="scrollTo('#contact')"
          class="text-white hover:text-gray-300"
          >Contact</a
        >
      </div>
      <div class="md:hidden">
        <button @click="toggleMenu" class="text-white">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div :class="['md:hidden', { hidden: !isOpen }]" class="bg-gray-800 p-4">
      <a
        href="#"
        @click.prevent="scrollTo('#hero')"
        class="block text-white hover:text-gray-300"
        >Company</a
      >
      <a
        href="#"
        @click.prevent="scrollTo('#gallery')"
        class="block text-white hover:text-gray-300"
        >Photos</a
      >
      <a
        href="#"
        @click.prevent="scrollTo('#booking')"
        class="block text-white hover:text-gray-300"
        >Book a Table</a
      >
      <a
        href="#"
        @click.prevent="scrollTo('#contact')"
        class="block text-white hover:text-gray-300"
        >Contact</a
      >
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    scrollTo(selector) {
      this.$scrollTo(selector, 500, { easing: "ease" });
    },
  },
};
</script>
