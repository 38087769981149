<template>
  <div id="app">
    <NavBar />
    <HeroSection />
    <TwoColumnSection />
    <PhotoGallery />
    <BookingForm />
    <FooterSection />
    <router-view />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import HeroSection from "./components/HeroSection.vue";
import TwoColumnSection from "./components/TwoColumnSection.vue";
import PhotoGallery from "./components/PhotoGallery.vue";
import BookingForm from "./components/BookingForm.vue";
import FooterSection from "./components/FooterSection.vue";

export default {
  components: {
    NavBar,
    HeroSection,
    TwoColumnSection,
    PhotoGallery,
    BookingForm,
    FooterSection,
  },
};
</script>
