<template>
  <footer id="contact" class="bg-gray-800 text-white py-16">
    <div class="container mx-auto flex flex-wrap">
      <div class="w-full md:w-1/2 p-4">
        <h3 class="text-3xl md:text-5xl mb-4">Contact</h3>
        <p class="text-xl mb-4">Rijnkade 62 6811HC Arnhem</p>
        <p class="text-xl mb-4">+31 6 57191242</p>
      </div>
      <div class="w-full md:w-1/2 p-4">
        <h3 class="text-3xl md:text-5xl mb-4">Vind Ons</h3>
        <a
          href="https://maps.app.goo.gl/m5k7Q69ctDNxqdGs9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="w-full h-64 bg-gray-700 rounded-lg shadow-lg">
            <img
              src="@/assets/map.jpg"
              alt="Restaurant Locatie"
              class="w-full h-full object-cover rounded-lg"
            />
          </div>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>
