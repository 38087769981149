<template>
  <section id="gallery" class="py-40 bg-gray-100">
    <div class="container mx-auto text-center">
      <h2 class="text-3xl md:text-7xl font-medium">Our Gallery</h2>
      <div class="flex flex-wrap justify-center">
        <div class="w-full md:w-1/3 p-4">
          <img
            src="@/assets/gallery-1.jpg"
            alt="Gallery Image 1"
            class="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
        <div class="w-full md:w-1/3 p-4">
          <img
            src="@/assets/gallery-2.jpg"
            alt="Gallery Image 2"
            class="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
        <div class="w-full md:w-1/3 p-4">
          <img
            src="@/assets/gallery-3.jpg"
            alt="Gallery Image 3"
            class="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PhotoGallery",
};
</script>
