<template>
  <section id="hero" class="py-16">
    <div class="container mx-auto flex flex-wrap">
      <div class="w-full md:w-1/2 p-4 pr-10">
        <h2 class="text-3xl md:text-7xl font-medium">Our Story</h2>
        <p class="text-xl md:text-3xl mb-8 mr-10">
          2tek werd geboren uit onze liefde voor de Egeïsche keuken en de rijke
          tradities van de regio. Het idee om een restaurant in Arnhem te openen
          kwam voort uit de wens om de smaken van de Egeïsche kust te delen met
          de stad. We wilden een plek creëren waar mensen samen kunnen genieten
          van verse, authentieke gerechten, bereid met de beste olijfolie en de
          mooiste ingrediënten.
        </p>
        <button
          class="text-2xl bg-gray-800 text-white py-2 px-6 rounded-full font-semibold hover:bg-gray-700"
          @click="scrollToBooking"
        >
          RESERVEREN
        </button>
      </div>
      <div class="w-full md:w-1/2 p-4">
        <img
          src="@/assets/2tek.jpg"
          alt="Restaurant Interior"
          class="w-full h-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  </section>

  <section class="py-16">
    <div class="container mx-auto flex flex-wrap">
      <div class="w-full md:w-1/2 p-4">
        <img
          src="@/assets/menu.jpg"
          alt="Restaurant Interior"
          class="w-full h-auto rounded-lg shadow-lg"
        />
      </div>
      <div class="w-full md:w-1/2 p-4 pl-10">
        <h2 class="text-3xl md:text-7xl font-medium my-8">Our Menu</h2>
        <p class="text-xl md:text-3xl mb-8">
          De keuken van 2tek in Arnhem valt op met haar mezze’s en gerechten
          bereid met verse olijfolie uit de Egeïsche regio. Gegrild vlees en vis
          worden gecombineerd met traditionele recepten. Zeytinyağlı gerechten,
          smaakvolle mezze's en verse zeevruchten brengen de unieke sfeer van de
          Egeïsche keuken naar Arnhem. 2tek brengt de verfijnde en gezonde
          smaken van de Egeïsche regio naar de stad.
        </p>
        <a
          href="https://2tek.nl/menu-2tek.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            class="text-lg bg-gray-800 text-white py-2 px-6 rounded-full font-semibold hover:bg-gray-700"
          >
            MENU KAART
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TwoColumnSection",
  methods: {
    scrollToBooking() {
      this.$scrollTo("#booking", 1200, { easing: "ease" });
    },
  },
};
</script>
