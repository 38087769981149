<template>
  <section
    id="hero"
    class="hero bg-cover bg-center h-screen flex items-center"
    :style="{ backgroundImage: `url(${require('@/assets/hero-image.jpg')})` }"
  >
    <div class="container mx-auto text-center text-white">
      <h1 class="text-4xl md:text-8xl font-normal mb-4">
        The First Aegean-Inspired Restaurant in Holland
      </h1>
      <!-- <p class="text-2xl mb-8">Een feest voor de zintuigen!</p> -->

      <button
        class="md:mr-4 mt-1 md:mt-2 text-2xl leading-xs border-2 border-white text-white py-5 px-10 rounded-full font-semibold hover:bg-white hover:text-gray-800"
        @click="scrollToBooking"
      >
        RESERVEREN
      </button>
      <a
        href="https://2tek.nl/menu-2tek.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          class="text-2xl mt-1 md:mt-2 leading-xs bg-white text-gray-800 py-5 px-10 rounded-full font-semibold hover:bg-gray-200"
        >
          MENU KAART
        </button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
  methods: {
    scrollToBooking() {
      this.$scrollTo("#booking", 1200, { easing: "ease" });
    },
  },
};
</script>
