<template>
  <section
    id="booking"
    class="py-16 bg-cover bg-center relative"
    :style="{ backgroundImage: `url(${require('@/assets/footer.jpg')})` }"
  >
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="container mx-auto relative z-10 flex justify-end">
      <div class="w-full md:w-1/2 p-4">
        <h2 class="text-3xl md:text-5xl text-white text-center mb-8">
          Boek een Tafel
        </h2>
        <form
          action="https://formspree.io/f/mnnqzjbg"
          method="POST"
          class="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-lg"
        >
          <div class="mb-4">
            <label for="name" class="block text-gray-700 text-sm font-bold mb-2"
              >Naam</label
            >
            <input
              type="text"
              id="name"
              name="name"
              class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Uw Naam"
              required
            />
          </div>
          <div class="mb-4">
            <label
              for="email"
              class="block text-gray-700 text-sm font-bold mb-2"
              >E-mail</label
            >
            <input
              type="email"
              id="email"
              name="email"
              class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Uw E-mail"
              required
            />
          </div>
          <div class="mb-4">
            <label for="date" class="block text-gray-700 text-sm font-bold mb-2"
              >Datum</label
            >
            <input
              type="date"
              id="date"
              name="date"
              class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              required
            />
          </div>
          <div class="mb-4">
            <label for="time" class="block text-gray-700 text-sm font-bold mb-2"
              >Tijd</label
            >
            <input
              type="time"
              id="time"
              name="time"
              class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              required
            />
          </div>
          <div class="mb-4">
            <label
              for="guests"
              class="block text-gray-700 text-sm font-bold mb-2"
              >Aantal Gasten</label
            >
            <input
              type="number"
              id="guests"
              name="guests"
              class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Aantal Gasten"
              required
            />
          </div>
          <button
            type="submit"
            class="w-full bg-indigo-500 text-white py-2 px-4 rounded-lg font-semibold hover:bg-indigo-600"
          >
            Boek Nu
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BookingForm",
};
</script>
